import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://cfd67efaf6e4496fa0e0d360d4dbcaa2@sentry.io/1554385',
  // @ts-ignore: Only enable when you are debugging Sentry usage locally!
  // enableInExpoDevelopment: true,
  release: process.env.REVISION_TAG,
  environment: process.env.REVISION_ENVIRONMENT,
});

export default Sentry;
